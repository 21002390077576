import { loginSignUpErrorMessages } from '@/components/multiFactorAuth/helpers';
import { Button } from '@mui/material';
import { sendEmailVerification } from 'firebase/auth';
import { useSnackbar } from 'notistack';

export default function useShowResentVerifyEmailSnackBar() {
	const { enqueueSnackbar } = useSnackbar();
	
	return ( e, newUser ) =>
		enqueueSnackbar( loginSignUpErrorMessages[ e.code || e.message || e ] || 'Failed to authenticate.', {
			variant: 'warning',
			action : () => e.code === 'auth/email-not-verified' || e === 'auth/email-not-verified' ? (
				<Button
					onClick={async () => {
						if ( !newUser ) return;
						await sendEmailVerification( newUser );
						enqueueSnackbar( 'Email sent', { variant: 'success' } );
					}}>
					Resend Verification Email
				</Button>
			) : undefined,
		} );
}

